<template>
  <div class="print-sect" id="placement-receipt" style="direction: rtl;display: none">
    <div style="direction: rtl; width: 255px;">
      <table class="table table-striped">
        <tbody style="font-weight:bold !important">
          <tr>
            <td colspan="2">{{ receipt.code }} <span style="float:left">{{ receipt.created_at }}</span></td>
          </tr>
          <tr style="font-weight:bold !important">
            <td style="font-weight:bold !important">المستخدم: {{ receipt.user_name }}</td>
            <td>{{ receipt.branch }}</td>
          </tr>
          <tr v-if="!receipt.lecture && !receipt.revesion">
            <td>نوع الإيصال: {{ receipt.receipt_type_name }}
            </td>
            <!-- <td v-if="!receipt.lecture && !receipt.revesion">
            نوع الحركة : {{ receipt.transaction_type }}
            </td> -->
          </tr>
          <tr v-if="receipt.lecture && receipt.transaction_type === 'استرداد'">
            <td>نوع الإيصال: {{ receipt.receipt_type_name }}
            </td>
            <td>
             الحركة النقدية: {{ 'استرداد حضور' }}
            </td>
          </tr>
          <tr v-if="receipt.lecture && receipt.transaction_type != 'استرداد'">
            <td colspan="2" style="text-align: center">
              <span style="font-size:35px !important; font-weight: 900  !important;">{{ receipt.number }}</span>
            </td>
          </tr>
          <tr v-if="receipt.lecture">
            <td> المحاضرة : {{ receipt.lecture }}</td>
            <td>رقم الدورة : {{ receipt.course_number }}</td>
          </tr>
          <tr>
            <td colspan="2">الدارس : {{ receipt.student_number + ' - ' + receipt.student}}</td>
          </tr>
          <tr v-if="receipt.course && !receipt.lecture">
            <td v-if="!receipt.revesion && receipt.course" colspan="2">الدورة: {{ receipt.course_number + '-' + receipt.course }}</td>
            <td v-else>المحاضرة : {{ receipt.selectedLectures}} -  {{receipt.course }}</td>
          </tr>
          <tr v-if="receipt.offer && !receipt.lecture">
            <td v-if="!receipt.revesion" colspan="2">العرض: {{ receipt.offer }}</td>
          </tr>
          <tr v-if="receipt.course && receipt.lecture">
            <td  class="info" colspan="2" style="font-weight:bold !important">اسم الدورة: {{ receipt.course }}</td>
          </tr>
          <tr v-if="!receipt.lecture">
            <td>القيمة: {{ $filters.number(receipt.amount) }}</td>
            <td>البيان : {{ receipt.description }}</td>
          </tr>
          <tr  style="border-top: 1px solid" v-if="!receipt.lecture && (receipt.course || receipt.offer)">
          <td>المسدد : {{ $filters.number(receipt.payed) }}</td>
          <td>المتبقي : {{ $filters.number(receipt.remain) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import setMaterialInput from '@/assets/js/material-input.js'
export default {
  name: 'PrintReceipt',
  props: {
    receipt: {}
  },
  mounted () {
    setMaterialInput()
  }
}
</script>
<style>
#print-receipt .table > :not(caption) > * > * {
  padding: 0px !important;
}
</style>
